<template>
  <cui-a-c-l :roles="[1]" redirect>
    <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Filters</strong>
            </div>
            <div class="text-muted">Filtering options</div>
          </div>
          <div class="card-body">
            <a-form>
              <a-divider>Search XML Filters</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Non Empty Referer">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Ad click with no referer will be filtered</span>
                  </template>
                  <a-switch @change="xmlUpdate" checked-children="Enabled" un-checked-children="Disabled"
                            v-model="xmlFilterData.NonEmptyReferer"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Referer Match">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Referer in ad click and "url" parameter in Publisher feed XML request should match.</span>
                  </template>
                  <a-switch @change="xmlUpdate" checked-children="Enabled" un-checked-children="Disabled"
                            v-model="xmlFilterData.RefererMatch"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IP Match">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Real user's IP address in ad click and "user_ip" parameter in Publisher feed XML request should match.</span>
                  </template>
                  <a-switch @change="xmlUpdate" checked-children="Enabled" un-checked-children="Disabled" v-model="xmlFilterData.IpMatch"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="User-Agent Match">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Real user's User Agent in ad click and "ua" parameter in Publisher feed XML request should match.</span>
                  </template>
                  <a-switch @change="xmlUpdate" checked-children="Enabled" un-checked-children="Disabled" v-model="xmlFilterData.UaMatch"/>
                </a-tooltip>
              </a-form-item>
              <a-divider>Display Filters</a-divider>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Non Empty Referer">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Ad click with no referer will be filtered</span>
                  </template>
                  <a-switch @change="displayUpdate" checked-children="Enabled" un-checked-children="Disabled"
                            v-model="displayFilterData.NonEmptyReferer"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Referer Match">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Referer in ad click and "url" parameter should match.</span>
                  </template>
                  <a-switch @change="displayUpdate" checked-children="Enabled" un-checked-children="Disabled"
                            v-model="displayFilterData.RefererMatch"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IP Match">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Real user's IP address in ad click and user IP parameter should match.</span>
                  </template>
                  <a-switch @change="displayUpdate" checked-children="Enabled" un-checked-children="Disabled" v-model="displayFilterData.IpMatch"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="User-Agent Match">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Real user's User Agent in ad click and user agent parameter should match.</span>
                  </template>
                  <a-switch @change="displayUpdate" checked-children="Enabled" un-checked-children="Disabled" v-model="displayFilterData.UaMatch"/>
                </a-tooltip>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
  </cui-a-c-l>
</template>

<script>
import { getXMLFilter, updateXMLFilter } from '@/api/xml/filter'
import { getDisplayFilter, updateDisplayFilter } from '@/api/display/filter'
import CuiACL from '@/components/cleanui/system/ACL/index'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}

export default {
  components: {
    CuiACL,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      xmlFilterData: {
        NonEmptyReferer: false,
        RefererMatch: false,
        IpMatch: false,
        UaMatch: false,
      },
      displayFilterData: {
        NonEmptyReferer: false,
        RefererMatch: false,
        IpMatch: false,
        UaMatch: false,
      },
      labelCol,
      wrapperCol,
    }
  },
  methods: {
    xmlUpdate() {
      const payload = {}
      for (const col in this.xmlFilterData) {
        payload[col] = this.xmlFilterData[col] ? 1 : 0
      }
      updateXMLFilter(payload).then(response => {
        if (response.Status === 'Ok') {
          this.$notification.success({
            message: 'Successfully Changed',
            description: 'You have successfully changed filter.',
          })
        } else {
          this.$notification.error({
            message: 'Changes Failed',
            description: response.Error,
          })
        }
      })
    },
    displayUpdate() {
      const payload = {}
      for (const col in this.displayFilterData) {
        payload[col] = this.displayFilterData[col] ? 1 : 0
      }
      updateDisplayFilter(payload).then(response => {
        if (response.Status === 'Ok') {
          this.$notification.success({
            message: 'Successfully Changed',
            description: 'You have successfully changed filter.',
          })
        } else {
          this.$notification.error({
            message: 'Changes Failed',
            description: response.Error,
          })
        }
      })
    },
  },
  created() {
    getXMLFilter().then(response => {
      if (response != null) {
        for (const col in response) {
          if (typeof this.xmlFilterData[col] !== 'undefined') {
            this.xmlFilterData[col] = response[col] === 1
          }
        }
      }
    }).catch(err => {
      // TODO: create notification
      console.log(err)
    })
    getDisplayFilter().then(response => {
      if (response != null) {
        for (const col in response) {
          if (typeof this.displayFilterData[col] !== 'undefined') {
            this.displayFilterData[col] = response[col] === 1
          }
        }
      }
    }).catch(err => {
      // TODO: create notification
      console.log(err)
    })
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
