import apiClient from '@/services/axios'

const END_POINT = '/ortb/filters'

/**
 * Get filter info
 *
 * @returns {Promise} - Request promise
 */
export async function getDisplayFilter() {
  return apiClient.get(END_POINT + '/get').then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}

/**
 * Update Filter info. All arguments optional
 *
 * @param {Object} payload - Update parameters
 * @returns {Promise} - Request promise
 */
export async function updateDisplayFilter(payload) {
  if (typeof payload !== 'object' || payload == null) {
    return false
  }
  // Parameter list
  const parameters = {
    NonEmptyReferer: { type: 'int', required: false },
    RefererMatch: { type: 'int', required: false },
    IpMatch: { type: 'int', required: false },
    UaMatch: { type: 'int', required: false },
  }

  const requestData = { Filters: apiClient.prepare(payload, parameters) } // TODO: validate locally

  return apiClient.post(END_POINT + '/update', requestData).then(response => {
    if (response) {
      return response.data
    }
    return false
  }).catch(err => console.log(err))
}
